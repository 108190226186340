<template>
  <div>
    <v-dialog v-model="dialog" max-width="720" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1"> รายละเอียด </v-tab>
          <v-tab href="#tab-2"> คำอธิบาย </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'">
            <v-card flat height="400">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <div>
                      <img
                        class="preview my-3"
                        :src="previewOriginal"
                        style="width: 180px; height: 180px"
                        alt=""
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.MenuId"
                      label="รหัส"
                      dense
                      required
                      readonly
                      ref="MenuId"
                      @keydown.enter="NextFocus('MenuTypeId')"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="item.MenuTypeId"
                      :items="menuTypeList"
                      item-value="MenuTypeId"
                      item-text="MenuTypeName"
                      :return-object="false"
                      dense
                      label="ประเภทเมนู"
                      ref="MenuTypeId"
                      @change="NextFocus('MenuName')"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="item.MenuName"
                      label="ชื่อ"
                      dense
                      required
                      ref="MenuName"
                      @keydown.enter="NextFocus('MenuBarcode')"
                    ></v-text-field>
                    <v-text-field
                      v-model.number="item.Price"
                      label="ราคา"
                      type="number"
                      dense
                      required
                      ref="Price"
                      @keydown.enter="NextFocus('bom_id')"
                    ></v-text-field>
                    <v-text-field
                      v-model="item.MenuBarcode"
                      label="บาร์โค้ด"
                      dense
                      required
                      ref="MenuBarcode"
                      @keydown.enter="NextFocus('UnitId')"
                    ></v-text-field>
                    <v-checkbox
                      v-model="item.VatType"
                      :false-value="0"
                      :true-value="1"
                      label="ภาษี"
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="item.bom_id"
                      :items="bomList"
                      item-value="bom_id"
                      item-text="bom_name"
                      :return-object="false"
                      dense
                      label="สูตรผลิต"
                      ref="bom_id"
                      @change="changeBom('bom_id')"
                      @keydown.enter="NextFocus('material_id')"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.material_id"
                      :items="materialList"
                      item-value="material_id"
                      item-text="material_name"
                      :return-object="false"
                      dense
                      label="วัตถุดิบ"
                      ref="material_id"
                      @change="changeBom('material_id')"
                      @keydown.enter="NextFocus('OnlineStatus')"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.UnitId"
                      :items="unitList"
                      item-value="UnitId"
                      item-text="UnitName"
                      :return-object="false"
                      dense
                      label="หน่วย"
                      ref="UnitId"
                      @keydown.enter="NextFocus('Price')"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="item.Status"
                      :items="enumStatus"
                      item-value="id"
                      item-text="name"
                      :return-object="false"
                      dense
                      label="สถานะ"
                      ref="Status"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="item.OnlineStatus"
                      :items="enumStatus"
                      item-value="id"
                      item-text="name"
                      :return-object="false"
                      dense
                      label="สั่งออนไลน์"
                      ref="OnlineStatus"
                      @keydown.enter="NextFocus('OnlineSort')"
                    ></v-autocomplete>
                    <v-text-field
                      v-model.number="item.OnlineSort"
                      label="ลำดับออนไลน์"
                      type="number"
                      dense
                      required
                      ref="OnlineSort"
                      @keydown.enter="NextFocus('Status')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4"> </v-col>
                  <v-col cols="12" md="5">
                    <v-file-input
                      v-model="filename"
                      show-size
                      label="Select Image"
                      accept="image/*"
                      @change="selectImage"
                    ></v-file-input>
                    <!-- <div v-if="previewImage">
                      <div>
                        <img
                          class="preview my-3"
                          :src="previewImage"
                          style="width: 180px; height: 180px"
                          alt=""
                        />
                      </div>
                    </div> -->
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="success" dark small @click="upload">
                      Upload
                      <v-icon right dark>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'tab-2'">
            <v-card flat height="400">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <div>
                      <img
                        class="preview my-3"
                        :src="previewOriginalDesc"
                        style="width: 180px; height: 180px"
                        alt=""
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-file-input
                      v-model="filename_desc"
                      show-size
                      label="Select Image"
                      accept="image/*"
                      @change="selectImageDesc"
                    ></v-file-input>
                    <!-- <div v-if="previewImageDesc">
                      <div>
                        <img
                          class="preview my-3"
                          :src="previewImageDesc"
                          style="width: 180px; height: 180px"
                          alt=""
                        />
                      </div>
                    </div> -->
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="success" dark small @click="upload_desc">
                      Upload
                      <v-icon right dark>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";
//import axios from "axios";

export default {
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      tab: null,
      filename: null,
      filename_desc: null,
      currentImage: undefined,
      previewImage: undefined,
      currentImageDesc: undefined,
      previewImageDesc: undefined,
      previewOriginal: "",
      previewOriginalDesc: "",
      imagePath: "",
      descPath: "",
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      unitList: [],
      bomList: [],
      materialList: [],
      menuTypeList: [],
    };
  },
  mounted() {
    this.getUnitList();
    this.getMenuTypeList();
    this.getMaterialList();
    this.getBOMList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("settings", ["parameter"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "actFoodMenuCreate",
      "actFoodMenuUpdate",
      "actFoodMenuImageUpload",
      "actFoodMenuDescUpload",
      "actUnitGets",
      "actMenuTypeShow",
      "getMaterialAll",
      "getBomActive",
    ]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.currentImage = null;
      this.previewImage = null;
      this.filename = null;

      this.currentImageDesc = null;
      this.previewImageDesc = null;
      this.filenameDesc = null;

      this.closeModal();
    },
    getMenuTypeList() {
      this.actMenuTypeShow().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.menuTypeList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getUnitList() {
      this.actUnitGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.unitList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getMaterialList() {
      this.getMaterialAll().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.materialList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getBOMList() {
      this.getBomActive().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.bomList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    changeBom(r) {
      console.log(r);
      if (r == "bom_id") {
        this.itemEdit.material_id = "";
      } else {
        this.itemEdit.bom_id = "";
      }
    },
    submit() {
      if (this.imagePath != "") {
        this.itemEdit.Photo = this.imagePath;
        this.imagePath = "";
      }

      if (this.descPath != "") {
        this.itemEdit.description_photo = this.descPath;
        this.descPath = "";
      }

      this.currentImage = null;
      this.previewImage = null;
      this.filename = null;
      this.currentImageDesc = null;
      this.previewImageDesc = null;
      this.filenameDesc = null;

      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.CreatedBy = this.user.UserId;
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actFoodMenuCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId;
        console.log(this.itemEdit);
        this.actFoodMenuUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            // this.uploadImage();
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
    },
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.message = "";
    },
    selectImageDesc(image_desc) {
      this.currentImageDesc = image_desc;
      this.previewImageDesc = URL.createObjectURL(this.currentImageDesc);
      this.message = "";
    },
    upload() {
      if (!this.currentImage) {
        this.message = "Please select an Image!";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("file", this.currentImage);
      if (this.previewOriginal != "") {
        bodyFormData.append("delete_url", this.previewOriginal);
      }

      this.actFoodMenuImageUpload(bodyFormData).then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.imagePath = data.result;
          this.previewOriginal = this.imagePath;

          this.currentImage = null;
          this.previewImage = null;
          this.filename = null;
        } else {
          this.Error(data.result);
        }
      });
    },
    upload_desc() {
      if (!this.currentImageDesc) {
        this.message = "Please select an Image!";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("file", this.currentImageDesc);
      if (this.previewOriginalDesc != "") {
        bodyFormData.append("delete_url", this.previewOriginalDesc);
      }

      this.actFoodMenuDescUpload(bodyFormData).then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.descPath = data.result;
          this.previewOriginalDesc = this.descPath;

          this.currentImageDesc = null;
          this.previewImageDesc = null;
          this.filename_desc = null;
        } else {
          this.Error(data.result);
        }
      });
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
      this.image = { name: this.itemEdit.Photo };
      // this.preview = this.parameter.PathImage + this.itemEdit.Photo;
      this.previewOriginal = this.itemEdit.Photo;
      this.previewOriginalDesc = this.itemEdit.description_photo;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>
